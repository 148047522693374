import firebase from 'firebase';

const firebaseConfig = {

  apiKey: 'AIzaSyD0HJ0z-UGQrNCYVuheTzY8e-f2M-LhHzA',

  authDomain: 'euroloeads-2021.firebaseapp.com',

  projectId: 'euroloeads-2021',

  storageBucket: 'euroloeads-2021.appspot.com',

  messagingSenderId: '776278123684',

  appId: '1:776278123684:web:cdcf461c43fa62d7163231',

  measurementId: 'G-KDT10G04FX'

};


// == initialize firebase (must use secondary account) == //

const app = firebase.initializeApp(firebaseConfig, 'euroleads');
firebase.analytics(app);

const db = firebase.firestore(app);

// == exports == //

export const euroleadsCandidatesRef = db.collection('candidates');
export const euroleadsReportsRef = db.collection('reports');
