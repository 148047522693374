// == react imports == //

import React from 'react';
import PropTypes from 'prop-types';

// == styles and images == //

import logo from '../images/icons/linkedin.png';  // linkedin logo
import '../styles/components/linkedinButton.css'; // stylesheet

// == component == //

/**
 * LinkedInButton component
 *
 * @param linkedin {String} linkedin link
 * @param handler {Function} function to handle click
 * @param className {String} className for the div containing the button
 *
 * @returns {JSX.Element} linkedin button
 */
const LinkedInButton = ({linkedin, handler, className}) => {

  if (linkedin)
    return (
      <div className={className ? className : 'LinkedInButton'}>

        <a href={linkedin}>

          <button className="LinkedinButton">

            <div className="container">


              <img src={logo} alt="linkedin icon"/>

              <p>LinkedIn</p>

            </div>

          </button>

        </a>

      </div>
    );

  else
    return (
      <div className={className ? className : 'LinkedInButton'}>

        <button className="NotLinkedinButton" onClick={handler}>

          <div className="container">

            <img src={logo} alt="linkedin icon"/>

            <p>LinkedIn</p>

          </div>

        </button>

      </div>
    );
};

// == type checking == //

LinkedInButton.propTypes = {
  linkedin: PropTypes.string,
  handler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// == export == //

export default LinkedInButton;