// == react imports == //

import React from 'react';
import PropTypes from 'prop-types';

// == styles == //

import '../styles/components/candidateInfo.css';

// == components == //

/**
 * Candidate info compnent
 *
 * @param {{
 *  first_name: String,
    last_name: String,
    grad_college: String,
    grad_major_1: String,
    grad_major_2: String,
    grad_major_3: String,
    grad_major_4: String,
    grad_major_5: String,
    undergrad_college: String,
    undergrad_major_1: String,
    undergrad_major_2: String,
    undergrad_major_3: String,
    undergrad_major_4: String,
    undergrad_major_5: String
 * }} candidate
 * @param {String} className
 * @returns {JSX.Element}
 * @constructor
 */
const CandidateInfo = ({candidate, className}) => {
  return (
    <div className={className ? className : 'CandidateInfo'}>

      <h1>
        {`${candidate.first_name} ${candidate.last_name}`}
      </h1>

      {
        (candidate.grad_college || candidate.grad_major_1 || candidate.grad_major_2 || candidate.grad_major_3
          || candidate.grad_major_4 || candidate.grad_major_5) &&

        <div>

          <h3>{candidate.grad_college ? `${candidate.grad_college}` : ''}</h3>

          <h4>Graduate</h4>

          <ul>

            {
              candidate.grad_major_1 && <li>{candidate.grad_major_1}</li>
            }

            {
              candidate.grad_major_2 && <li>{candidate.grad_major_2}</li>
            }

            {
              candidate.grad_major_3 && <li>{candidate.grad_major_3}</li>
            }

            {
              candidate.grad_major_4 && <li>{candidate.grad_major_4}</li>
            }

            {
              candidate.grad_major_5 && <li>{candidate.grad_major_5}</li>
            }

          </ul>

        </div>
      }

      {
        (candidate.undergrad_college || candidate.undergrad_major_1 || candidate.undergrad_major_2
          || candidate.undergrad_major_3 || candidate.undergrad_major_4 || candidate.undergrad_major_5) &&

        <div>

          <h3>{candidate.undergrad_college ? `${candidate.undergrad_college} ` : ''}</h3>

          <h4>Undergraduate</h4>

          <ul>

            {
              candidate.undergrad_major_1 && <li>{candidate.undergrad_major_1}</li>
            }

            {
              candidate.undergrad_major_2 && <li>{candidate.undergrad_major_2}</li>
            }

            {
              candidate.undergrad_major_3 && <li>{candidate.undergrad_major_3}</li>
            }

            {
              candidate.undergrad_major_4 && <li>{candidate.undergrad_major_4}</li>
            }

            {
              candidate.undergrad_major_5 && <li>{candidate.undergrad_major_5}</li>
            }

          </ul>

        </div>
      }

    </div>
  );
};

// == typechecking == //

CandidateInfo.propTypes = {
  candidate: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    grad_college: PropTypes.string,
    grad_major_1: PropTypes.string,
    grad_major_2: PropTypes.string,
    grad_major_3: PropTypes.string,
    grad_major_4: PropTypes.string,
    grad_major_5: PropTypes.string,
    undergrad_college: PropTypes.string,
    undergrad_major_1: PropTypes.string,
    undergrad_major_2: PropTypes.string,
    undergrad_major_3: PropTypes.string,
    undergrad_major_4: PropTypes.string,
    undergrad_major_5: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

// == exports == //

export default CandidateInfo;