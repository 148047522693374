// == react imports == //

import React from 'react';
import PropTypes from 'prop-types';

// == styles == //

import logo from '../images/icons/whatsapp.png';
import '../styles/components/whatsappButton.css';

// == components == //

/**
 * WhatsappButton component
 *
 * @param number {Number|String} phone number
 * @param handler {Function} handler
 * @param className {String} classname for the div containing the buttons
 *
 * @returns {JSX.Element} WhatsappButton component
 */
const WhatsappButton = ({number, handler, className}) => {

  if (number)
    return (
      <div className={className ? className : 'WhatsappButton'}>

        <a href={`https://wa.me/${number}`}>

          <button className="WhatsappButton">

            <div className="container">

              <img src={logo} alt="whatsapp icon"/>

              <p>WhatsApp</p>

            </div>

          </button>

        </a>

      </div>
    );
  else
    return (
      <div className={className ? className : 'WhatsappButton'}>

        <button className="NotWhatsappButton" onClick={handler}>

          <div className="container">

            <img src={logo} alt="whatsapp icon"/>

            <p>WhatsApp</p>

          </div>

        </button>

      </div>
    );
};

// == typechecking == //

WhatsappButton.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handler: PropTypes.func.isRequired,
  className: PropTypes.string
};

// == exports == //

export default WhatsappButton;