/**
 * file for setting up firebase and authenticating the application
 */
import firebase from 'firebase';

// -- authentication key == //

const firebaseConfig = {

  apiKey: 'AIzaSyAAg3chjTCmrtTcOQwU7QGBBGmwPK-01Ow',

  authDomain: 'brasa-summit-2021.firebaseapp.com',

  databaseURL: 'https://brasa-summit-2021-default-rtdb.firebaseio.com',

  projectId: 'brasa-summit-2021',

  storageBucket: 'brasa-summit-2021.appspot.com',

  messagingSenderId: '561628481799',

  appId: '1:561628481799:web:019651e5cd22fa9b075da2',

  measurementId: 'G-DLR21RGW06'

};

// == initialize firebase == //

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();

// == exports == //

export const candidatesRef = db.collection('candidates');
export const reportsRef = db.collection('reports');
