/**
 * whatsapp button
 */
import React from 'react';
import logo from '../images/icons/mail.png';
import '../styles/components/emailButton.css';
import PropTypes from 'prop-types';

/**
 * Email button component
 *
 * @param handler {function} handler function for the button
 * @param added {boolean} whether the candidate was already added to the report
 * @param className {string} className for the div
 *
 * @returns {JSX.Element} email button element
 */
const EmailButton = ({ handler, added, className }) => {

  return (
    <div className={className ? className : 'EmailButton'}>

      <button className={!added ? 'EmailButton' : 'RemoveButton'} onClick={handler}>

        <div className='container'>

          <img src={logo} alt='email logo' />

          <p>{!added ? 'Add to Report' : 'Remove'}</p>

        </div>

      </button>

    </div>
  );
};

EmailButton.propTypes = {
  handler: PropTypes.func.isRequired,
  added: PropTypes.bool,
  className: PropTypes.string,
};

export default EmailButton;