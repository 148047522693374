// == react imports == //

import React from 'react';
import PropTypes from 'prop-types';

// == styles == //

import '../styles/components/navButtons.css';

// == component == //

/**
 * Navigation buttons component
 *
 * @param candidate {Number} id of the candidate
 * @param className {String} classname for the div containing the buttons
 *
 * @returns {JSX.Element} NavButtons component
 */
const NavButtons = ({ candidate, className }) => {
  return (
    <div className={className ? className : 'NavButtons'}>

      {
        candidate.id > 0 ?
          <a href={`./${Number(candidate.id) - 1}`} >

            <button>
                            Previous
            </button>

          </a>
          :

          ''
      }

      <a href={`./${Number(candidate.id) + 1}`}>

        <button>
                    Next
        </button>

      </a>

    </div>
  );
};

// == typechecking == //

NavButtons.propTypes = {
  candidate: PropTypes.number.isRequired,
  className: PropTypes.string
};

// == exports == //

export default NavButtons;