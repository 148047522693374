/**
 * Candidate page of the nfc linktree application
 */
/* react imports */

import React from 'react';
import {useEffect, useState} from 'react'; // effect and state import
import {Redirect, useParams} from 'react-router-dom'; // use url params
import {useSwipeable} from 'react-swipeable';

/* custom components imports */

import EmailButton from '../components/EmailButton'; // email button component
import LinkedInButton from '../components/LikedInButton'; // linkedin button component
import WhatsappButton from '../components/WhatsappButton'; // whatsapp button component
import CandidateInfo from '../components/CandidateInfo'; // candidate info component
import NavButtons from '../components/NavButtons';

/* material ui imports */

import {Grid, Snackbar} from '@material-ui/core'; // snackbar
import {Alert} from '@material-ui/lab'; // alert
import {makeStyles} from '@material-ui/core/styles'; // material ui styles
import CircularProgress from '@material-ui/core/CircularProgress'; // progress bar
import Button from '@material-ui/core/Button'; // button
import TextField from '@material-ui/core/TextField'; // textfield
import Dialog from '@material-ui/core/Dialog'; // dialog
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/* firebase */

import firebase from 'firebase';

/* images imports */

import swipeGif from '../images/swipe.gif';

/* styles imports */

import '../styles/pages/Main.css';
import PropTypes from 'prop-types'; // style for the page

/**
 * Generates the main page of the nfc card linktree application
 *
 * @param {firebase.CollectionReference} candidatesRef
 * @param {firebase.CollectionReference} reportsRef
 * @returns {JSX.Element} main page of the nfc card linktree application
 */
const Candidate = ({ candidatesRef, reportsRef }) => {

  // url params

  const {id} = useParams();  // requested candidate

  // states

  const [candidate, setCandidate] = useState('');  // candidate loaded
  const [report, setReport] = useState('');        // user report 
  const [added, setAdded] = useState('');          // whether the candidate is in the report
  const [email, setEmail] = useState(window.localStorage.getItem('email'));    // user email
  const [pageError, setPageError] = useState(false);            // error status
  const [loading, setLoading] = useState(false);                // loading candidate screen
  const [notFound, setNotFound] = useState(false);              // was the candidate found
  const [open, setOpen] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [showSwipe, setShowSwipe] = useState(!localStorage.getItem('notFirstTime'));

  // material ui config

  const useStyles = makeStyles(() => ({
    root: {
      borderRadius: '1rem',
    },
  }));
  const classes = useStyles();

  // effects

  useEffect(() => {   // tries to load a candidate

    const fetch = async () => {

      setLoading(true);
      const candidate = await candidatesRef.doc(id).get();

      if (candidate.exists) {
        setCandidate({
          ...candidate.data(),
          id: id,
          whatsapp: 'whatsapp' in candidate.data() && candidate.data().whatsapp
            ? Number.isFinite(candidate.data().whatsapp) ?
              candidate.data().whatsapp :
              candidate.data().whatsapp.replace(/\D/g, '') :
            undefined
        });
      }
      setLoading(false);
      setNotFound(true);
      return null;
    };

    fetch().then(() => {
      console.log('loaded');
    });

  }, [id, redirect]);

  useEffect(() => {   // tries to load a report or create a new one

    const fetch = async () => {

      const report = await reportsRef.doc(email).get();

      if (report.exists) {
        setAdded(report.data().candidates.includes(id));
        setReport({
          ...report.data(),
        });

      } else {
        setAdded(false);
        setReport({
          candidates: [],
          times: []
        });
      }

      return null;
    };
    if (email) {
      fetch();
    }

  }, [email, id, redirect]);

  // event handlers

  const handlers = useSwipeable({ // listens for swiping gestures

    onSwipedLeft: () => {
      setRedirect(`./${Number(id) + 1}`);
      setPageError('');
      setRedirect(null);
    },

    onSwipedRight: () => {
      if (Number(id) > 0) {
        setRedirect(`./${Number(id) - 1}`);
        setPageError('');
        setRedirect(null);

      } else {
        setPageError('This is the first candidate');
      }
    }

  });

  const handleChange = (e) => {   // handles email text changes
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());

    window.localStorage.setItem('email', e.target.value);
    setEmail(e.target.value);
    setInvalidEmail(!valid);

  };

  const addToReport = async () => {   // adds a candidate to the report
    report.candidates.push(id);
    report.times.push(new Date());
    reportsRef.doc(email).set(report).then(() => {
      setAdded(true);
    });

  };

  const removeFromReport = () => {    // removes a candidate from the report
    const index = report.candidates.indexOf(id);

    if (index > -1) {
      report.candidates.splice(index, 1);
      report.times.splice(index, 1);
    }

    reportsRef.doc(email).set(report).then(() => {
      setAdded(false);
    });

  };

  const handleClickOpen = () => {     // opens email popup
    setOpen(true);
  };

  const handleClose = () => { // closes email popup
    setOpen(false);
  };

  // main page

  if (redirect)

    return (

      <Redirect to={redirect}/>

    );

  else if (loading)

    return (

      <div className="Main">

        <div className={classes.root}>

          <CircularProgress/>

        </div>

      </div>

    );

  else if (candidate)

    return (

      <div className="Main" {...handlers}>

        <CandidateInfo candidate={candidate} className="CandidateInfo"/>

        <LinkedInButton
          linkedin={candidate.linkedin}
          handler={() => setPageError('The candidate has not provided a LinkedIn profile')}
          className="Linkedin"
        />

        <WhatsappButton
          number={candidate.whatsapp}
          handler={() => setPageError('The candidate has not provided a phone number')}
          className="Whatsapp"
        />

        <EmailButton handler={handleClickOpen} added={added} className="Save"/>

        <NavButtons candidate={candidate}/>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.root}>

          <DialogTitle id="form-dialog-title" className={classes.root}>

            {!added ? 'Add candidate to daily email report?' : 'Remove candidate from daily email report?'}

          </DialogTitle>

          <DialogContent>

            <DialogContentText>

              Add candidates to your daily report to receive an email at the end of the day with their
              relevant information such as their Whatsapp contact, LinkedIn profile, university, and
              majors.

            </DialogContentText>

            <TextField
              autoFocus={!email}
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              defaultValue={email}
              onChange={handleChange}
              fullWidth
              error={invalidEmail}
              helperText={invalidEmail ? 'Invalid email' : ''}
            />

            <small>Please check your spam folder as emails often end up there</small>

          </DialogContent>

          <DialogActions>

            <Button onClick={handleClose} color="primary">

              Cancel

            </Button>

            <Button onClick={() => {
              if (!invalidEmail) {

                if (added) {
                  removeFromReport();
                } else {
                  addToReport();
                }
                handleClose();
              }

            }} color="primary">

              {added ? 'Remove candidate' : 'Add candidate'}

            </Button>

          </DialogActions>

        </Dialog>

        <Dialog open={showSwipe} onClose={() => {
          setShowSwipe(false);
          localStorage.setItem('notFirstTime', true);
        }} aria-labelledby="form-dialog-title" className={classes.root}>

          <DialogTitle id="form-dialog-title" className={classes.root}>

            Swipe to navigate through candidates

          </DialogTitle>

          <DialogContent>

            <DialogContentText>

              If you are on mobile, you can swipe to navigate through candidates.

            </DialogContentText>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{minHeight: '50%'}}>

              <img src={swipeGif} alt="swiping finger" style={{
                maxWidth: '20%',
                maxHeight: '20%',
                height: 'auto',
              }}/>

            </Grid>


          </DialogContent>

          <DialogActions>

            <Button onClick={() => {
              setShowSwipe(false);
              localStorage.setItem('notFirstTime', true);
            }} color="primary">

              Close

            </Button>

          </DialogActions>

        </Dialog>


        <div className={classes.root}>

          <Snackbar
            open={Boolean(pageError)} autoHideDuration={3000} onClose={() => setPageError('')}
            className={classes.root}>

            <Alert onClose={() => setPageError('')} severity="error" className={classes.root}>

              {pageError}

            </Alert>

          </Snackbar>


        </div>

      </div>
    );

  else if (notFound)

    return (

      <div className="Main">

        <h1 className="NotFound">
          Candidate not found
        </h1>

      </div>

    );

  else

    return null;
};

Candidate.propTypes = {
  'candidatesRef': PropTypes.instanceOf(firebase.CollectionReference).isRequired,
  'reportsRef': PropTypes.instanceOf(firebase.CollectionReference).isRequired,
};

export default Candidate;