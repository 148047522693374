/* react imports */

import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {candidatesRef, reportsRef} from './firebase/summit';
import {euroleadsCandidatesRef, euroleadsReportsRef} from './firebase/euroleads';

/* pages imports */

import Candidate from './pages/Candidate';

function App() {
  return (
    <Router>

      <Switch>

        <Route path="/euroleads/:id">

          <Candidate candidatesRef={euroleadsCandidatesRef} reportsRef={euroleadsReportsRef}/>

        </Route>

        <Route path="/:id">

          <Candidate candidatesRef={candidatesRef} reportsRef={reportsRef}/>

        </Route>

      </Switch>

    </Router>

  );
}

/* exports */

export default App;
